import { Button, CircularProgress, Paper, TextField } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { grammer } from "../component/features/chatgptSlice";
import Title from "../component/Title";
import { toast } from "react-toastify";

function Grammar() {
  const dispatch = useDispatch();
  const { grammerResponse, isLoading } = useSelector(
    (store) => store.chatgptSlice
  );
  const [text, setText] = useState('');

  const submitHandler = () => {
    if (text.trim() === "") {
      return toast.warning("Please enter some text first.");
    }

    dispatch(grammer(text));
  };
  return (
    <Box>
      <Title
        mainTitle={"Grammar Section."}
        secondaryTitle={`Check for any grammar mistakes here.`}
      />
      <Stack direction="row" justifyContent="space-between">
        <Paper elevation={3} sx={{ width: "49%", height: "60vh", p: 1 }}>
          <Box sx={{ height: "8%", mb: 1, p: 1, borderBottom: "1px solid gray" }}>
            Enter your text below to check.
          </Box>
          <TextField
            id="outlined-multiline-flexible"
            multiline
            sx={{ width: "100%", mb: 1,  }}
            InputProps={{ sx: { height: "45vh", alignItems: "flex-start" } }}
            maxRows={8}
            onChange={(e) => setText(e.target.value)}
          />
          <Button onClick={submitHandler} variant="contained">
            Submit
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ width: "49%", height: "60vh", p: 1 }}>
          <Box
            sx={{ height: "8%", mb: 1, p: 1, borderBottom: "1px solid gray" }}
          >
            Correct sentence.
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex' , justifyContent:'center'}}>
      <CircularProgress />
    </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                border: "1px solid grey",
                height: "45vh",
                p: 2,
              }}
            >
              {grammerResponse !== null ? grammerResponse[0]?.text : ""}{" "}
            </Box>
          )}
        </Paper>
      </Stack>
    </Box>
  );
}

export default Grammar;
