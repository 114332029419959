import { Paper, Typography } from '@mui/material'
import React from 'react'

function Title({mainTitle, secondaryTitle}) {
  return (
    <Paper sx={{ p: 2, mb: 2 }}>
    <Typography variant="h5">{mainTitle}</Typography>
    <Typography>{secondaryTitle}</Typography>
  </Paper>
  )
}

export default Title
