import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Configuration, OpenAIApi } from "openai";
import { toast } from "react-toastify";
const initialState = {
  Nuser: "testUser",
  isLoading: false,
  isSidebarOpen: true,
  response: null,
  grammerResponse: null,
  explainerResponse:null,
  chatResponse:null,
  imageResponse:null,
};

const configuration = new Configuration({
  apiKey:process.env.REACT_APP_API_KEY,
});
const openai = new OpenAIApi(configuration);

export const qusAns = createAsyncThunk("qa/ausAns", async (query, thunkAPI) => {
  let object = {
    model: "text-davinci-003",
    temperature: 0,
    prompt: query,
    max_tokens: 200,
    top_p: 1,
    frequency_penalty: 0.0,
    presence_penalty: 0.0,
  };
  try {
    const response = await openai.createCompletion(object);
    return response.data;
  } catch (e) { 
    e &&  toast.error('Something went wrong. Please try again.');
  
  }
});

export const grammer = createAsyncThunk(
  "qa/grammer",
  async (query, thunkAPI) => {
    let object = {
      model: "text-davinci-003",
      prompt: `Correct this to standard English:\n\n${query}.`,
      temperature: 0,
      max_tokens: 60,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,
    };

    try {
      const response = await openai.createCompletion(object);
      return response.data;
    } catch (e) {
      e &&  toast.error('Something went wrong. Please try again.');
    }
  }
);

export const explainer = createAsyncThunk(
  "qa/explainer",
  async (query, thunkAPI) => {
    let object = {
      model: "code-davinci-002",
      // eslint-disable-next-line no-useless-escape
      prompt: `${query}\n\n\n\"\"\"\nHere's what the above code is doing:\n`,
      temperature: 0,
      max_tokens: 300,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      stop: ["\"\"\""],
    };

    try {
      const response = await openai.createCompletion(object);
      return response.data;
    } catch (e) {
      e &&  toast.error('Something went wrong. Please try again.');
    }
  }
);

  export const javascriptChat = createAsyncThunk(
    "qa/javascriptChat",
    async (query, thunkAPI) => {
      let object = {
        model: "code-davinci-002",
        prompt: `Convert this text to a  program:\n ${query} `,
        temperature: 0,
        max_tokens: 200,
        top_p: 1.0,
        frequency_penalty: 0.5,
        presence_penalty: 0.0,
        stop: ["You:"],
      };
  
      try {
        const response = await openai.createCompletion(object);
        return response.data;
      } catch (e) {
        e &&  toast.error('Something went wrong. Please try again.');
      }
    }
  );

  export const imageGenerator = createAsyncThunk(
    "qa/imageGenerator",
    async (query, thunkAPI) => {
      let object = {
        prompt: query,
  n: 1,
  size: "512x512",
      };
  
      try {
        const response = await openai.createImage(object);
        return response.data;
      } catch (e) {
        e &&  toast.error('Something went wrong. Please try again.');
      }
    }
  );

const chatgptSlice = createSlice({
  name: "qa",
  initialState,
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(qusAns.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(qusAns.fulfilled, (state, action) => {
      state.isLoading = false;
      state.response = action.payload.choices;
     
    });
    builder.addCase(qusAns.rejected, (state, action) => {
      state.isLoading = false;
      });

    builder.addCase(grammer.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(grammer.fulfilled, (state, action) => {
      state.isLoading = false;
      state.grammerResponse = action.payload.choices;
    });
    builder.addCase(grammer.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(explainer.pending, (state, action) => {
        state.isLoading = true;
      });
      builder.addCase(explainer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.explainerResponse = action.payload.choices;
      });
      builder.addCase(explainer.rejected, (state, action) => {
        state.isLoading = false;
      });

      builder.addCase(javascriptChat.pending, (state, action) => {
        state.isLoading = true;
      });
      builder.addCase(javascriptChat.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatResponse = action.payload.choices;
      });
      builder.addCase(javascriptChat.rejected, (state, action) => {
        state.isLoading = false;
      });

      builder.addCase(imageGenerator.pending, (state, action) => {
        state.isLoading = true;
      });
      builder.addCase(imageGenerator.fulfilled, (state, action) => {
        state.isLoading = false;
        // console.log(action.payload.data[0])
      
        state.imageResponse = action.payload.data[0];
      });
      builder.addCase(imageGenerator.rejected, (state, action) => {
        state.imageResponse = false;
      });
  },
});

export const { toggleSidebar } = chatgptSlice.actions;
export default chatgptSlice.reducer;
