import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Sidebar from './component/Sidebar';
import Navbar from './component/Navbar';
import HomePage from './pages/HomePage';
import Qna from './pages/Qna'
import Explainer from './pages/Explainer';
import ImageGenerator from './pages/ImageGenerator';
import CodeGenerator from './pages/CodeGenerator';
import Grammar from './pages/Grammar';



const mdTheme = createTheme();

function App() {
  return (
<ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar/>
        <Sidebar/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
                theme.palette.grey[100],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

         <Routes>
         <Route path='/' element={<HomePage/>}/>
         <Route path='/grammar' element={<Grammar/>}/>
         <Route path='/qna' element={<Qna/>} />
         <Route path='/explainer' element={<Explainer/>}/>
         <Route path='/js-chat' element={<CodeGenerator/>}/>
         <Route path='/image-generator' element={<ImageGenerator/>}/>
         </Routes>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
