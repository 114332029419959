import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  TextareaAutosize,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Title from "../component/Title";
import { toast } from "react-toastify";
import { imageGenerator } from "../component/features/chatgptSlice";

function ImageGenerator() {
  const dispatch = useDispatch();
  const { imageResponse, isLoading } = useSelector(
    (store) => store.chatgptSlice
  );

  const [text, setText] = useState("");

  const submitHandler = () => {
    if (text.trim() === "") {
      return toast.warning("Please enter some code first.");
    }

    dispatch(imageGenerator(text));
  };

  return (
    <Box>
      <Title
        mainTitle={"AI Image Generator Section."}
        secondaryTitle={`Add your inputs and create images as you want.`}
      />
      <Stack direction="row" justifyContent="space-between">
        <Paper elevation={3} sx={{ width: "49%", height: "60vh", p: 1 }}>
          <Box
            sx={{ height: "8%", mb: 1, p: 1, borderBottom: "1px solid gray" }}
          >
            Enter your inputs below.
          </Box>
          <TextareaAutosize
            id="outlined-multiline-flexible"
            // multiline
            style={{
              width: "100%",
              height: "45vh",
              padding: "10px",
              maxWidth: "100%",
              maxHeight: "45vh",
            }}
            maxRows={60}
            onChange={(e) => setText(e.target.value)}
          />
          <Button onClick={submitHandler} variant="contained">
            Submit
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ width: "49%", height: "60vh", p: 1 }}>
          <Box
            sx={{ height: "8%", mb: 1, p: 1, borderBottom: "1px solid gray" }}
          >
            AI generated image .
          </Box>

          {isLoading ? (
            <>
              {" "}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>{" "}
              <div>
                After loading, please wait for a while. It may take some time
                for the AI to create an image.
              </div>
            </>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {" "}
              {imageResponse && (
                <img
                  src={imageResponse?.url}
                  style={{
                    height: "360px",
                    width: "460px",
                    borderRadius: "5px",
                  }}
                  alt="ai-pic"
                />
              )}{" "}
            </Box>
          )}

          {/* <img src={imageResponse?.url} style={{height:"360px", width:"460px", borderRadius:'5px'}} alt='ai-pic'/> */}
        </Paper>
      </Stack>
    </Box>
  );
}

export default ImageGenerator;
