import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import TerminalIcon from "@mui/icons-material/Terminal";
import AssistantIcon from "@mui/icons-material/Assistant";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import {  NavLink } from "react-router-dom";

export const mainListItems = (
  <React.Fragment>
    <ListItemButton 
      to="/"  
      component={NavLink}
      sx={{
        "&.active": {
          fontWeight: 600,
          background: "#D9EEE1",
        },
      }}
    >
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="HomePage" />
    </ListItemButton>
    {/* </Link> */}

    <ListItemButton
      to="/qna"
      component={NavLink}
      sx={{
        "&.active": {
          fontWeight: 600,
          background: "#D9EEE1",
        },
      }}
    >
      <ListItemIcon>
        <QuestionAnswerIcon />
      </ListItemIcon>
      <ListItemText primary="Q&A" />
    </ListItemButton>

    <ListItemButton
      to="/grammar"
      component={NavLink}
      sx={{
        "&.active": {
          fontWeight: 600,
          background: "#D9EEE1",
        },
      }}
    >
      <ListItemIcon>
        <HistoryEduIcon />
      </ListItemIcon>
      <ListItemText primary="Grammar" />
    </ListItemButton>

    <ListItemButton
      to="/explainer"
      component={NavLink}
      sx={{
        "&.active": {
          fontWeight: 600,
          background: "#D9EEE1",
        },
      }}
    >
      <ListItemIcon>
        <TerminalIcon />
      </ListItemIcon>
      <ListItemText primary="Explain code" />
    </ListItemButton>

    <ListItemButton
      to="/js-chat"
      component={NavLink}
      sx={{
        "&.active": {
          fontWeight: 600,
          background: "#D9EEE1",
        },
      }}
    >
      <ListItemIcon>
        <AssistantIcon />
      </ListItemIcon>
      <ListItemText primary="Code Generator" />
    </ListItemButton>

    <ListItemButton
      to="/image-generator"
      component={NavLink}
      sx={{
        "&.active": {
          fontWeight: 600,
          background: "#D9EEE1",
        },
      }}
    >
      <ListItemIcon>
        <ImageSearchIcon />
      </ListItemIcon>
      <ListItemText primary="Image Generator" />
    </ListItemButton>
  </React.Fragment>
);
