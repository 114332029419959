import React from "react";
import MainFeaturedPost from "../component/MainFeaturedPost";

function HomePage() {
  return (
    <div>
      <MainFeaturedPost />
    </div>
  );
}

export default HomePage;
