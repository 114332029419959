import { Button, CircularProgress, Paper, TextField } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { qusAns } from "../component/features/chatgptSlice";
import Title from "../component/Title";
import { toast } from "react-toastify";


function Qna() {
  const dispatch = useDispatch();
  const { response, isLoading } = useSelector((store) => store.chatgptSlice);

  const [textInput, setTextInput] = useState("");
  const submitHanlder = () => {
    if (textInput.trim() === "") {
      return toast.warning("Please enter your question.");
    }
    dispatch(qusAns(textInput));
  };
  return (
    <Box>
      <Title
        mainTitle={"Questions & Answers Section."}
        secondaryTitle={`Here You can ask any question and AI will provide instant response to
          it.`}
      />
      <Paper elevation={3} sx={{ mt: 3, p: 2, backgroundColor: "light-blue" }}>
        <Stack>
          <Box
            sx={{
              boder: "black",
              height: "50vh",
              borderRadius: "5px",
              whiteSpace: "pre-wrap",
              overflow: "auto",
              p: 1, 
            }}
          >
            {isLoading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : response === null ? (
              <div>Please ask a Question.</div>
            ) : (
              response[0]?.text
            )}
          </Box>
          <Box>
            <TextField
              id="filled-basic"
              label="Your Question"
              sx={{ width: "80%" }}
              variant="filled"
              onChange={(e) => setTextInput(e.target.value)}
            />
            <Button
              sx={{ width: "20%", height: "55px" }}
              onClick={submitHanlder}
              variant="contained"
            >
              Find Answer
            </Button>
          </Box>
        </Stack>
      </Paper>
    </Box>
  );
}

export default Qna;
