import { Button, CircularProgress, Paper, TextareaAutosize} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { explainer } from "../component/features/chatgptSlice";
import Title from "../component/Title";
import { toast } from "react-toastify";


function Explainer() {
  const dispatch = useDispatch();
  const { explainerResponse, isLoading } = useSelector((store) => store.chatgptSlice);
  const [text, setText] = useState("");
  // const testref = /\n/;


  
  const submitHandler = () => {
    if (text.trim() === "") {
      return toast.warning("Please enter some code first.");
    }
    
    dispatch(explainer(text));
  };
  return (
    <Box>
     <Title mainTitle={'Code Explain Section.'} secondaryTitle={`Didn't understand the code? Try me now!`}/>
      <Stack direction="row" justifyContent="space-between">
        <Paper elevation={3} sx={{ width: "49%", height: "60vh", p: 1 }}>
          <Box
            sx={{ height: "8%", mb: 1, p: 1, borderBottom: "1px solid gray" }}
          >
            Enter your code below to explain.
          </Box>
          <TextareaAutosize
            id="outlined-multiline-flexible"
            // multiline
            style={{ width: '100%', height:'45vh',padding:'10px', maxWidth:'100%', maxHeight:'45vh', }}
            
            maxRows={60}
            onChange={(e) => setText(e.target.value)}
          />
          <Button onClick={submitHandler} variant="contained">
            Submit
          </Button>
        </Paper>
        <Paper elevation={3} sx={{ width: "49%", height: "60vh", p: 1 }}>
          <Box
            sx={{ height: "8%", mb: 1, p: 1, borderBottom: "1px solid gray" }}
          >
            Entered code explaination.
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex' , justifyContent:'center'}}>
      <CircularProgress />
    </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                border: "1px solid grey",
                height: "45vh",
                whiteSpace:'pre-wrap',
                overflow:'auto',
                p: 2,
              }}
            >
              {explainerResponse !== null ? explainerResponse[0]?.text : ""}{" "}
            </Box>
          )}
        </Paper>
      </Stack>
    </Box>
  );
}

export default Explainer;
